<template>
<FormBlock/>
</template>

<script>
import FormBlock from "@/components/ReferFormBlock.vue";

export default {
  name: 'ReferSomeonePage',
  components: {FormBlock}
}
</script>

<style lang="scss" scoped>
.form-container {
  min-height: 100vh;
  padding-top: 143px;
}
</style>