<template>
  <div>
    <div class="banner">
      <div class="container">
        <div class="banner-content">
          <h1 class="text-default">All you need to know</h1>
          <h1 class="text-bold-700">about REP</h1>
        </div>
      </div>
      <img src="@/assets/images/green-purple-corner.svg" alt="" class="corner">
    </div>

    <div class="content">
      <div class="container">
        <div class="wrapper">
          <a class="logo" @click="redirect()">
            <img alt="WJ" :src="logoUrl">
          </a>

          <div class="accordions">
            <Accordion is-blue-arrow="true" :opened="true"
                       @opened="changeLogo(0)"
            >
              <template v-slot:title>
                <h2 class="main-color">The Refugee Employability</h2>
              </template>
              <template v-slot:content>
                <div class="ac-content">
                  <p>
                    The Refugee Employability Programme (REP) is a UK government-funded programme to support refugees to
                    integrate into life in the UK, contribute to the economy and build a sustainable and long-term
                    future.
                  </p>
                  <p>
                    The REP will complement existing support delivered across mainstream services. The support shall not
                    duplicate the services already provided to you by the Local Authority or
                    Community Sponsorship groups.
                  </p>

                  <p class="ac-content-link" @click="showHowItWorks">How it works</p>
                  <p class="ac-content-link-1" @click="showAccessibilityModal">Accessible Support</p>
                </div>
              </template>
            </Accordion>

            <Accordion is-blue-arrow="true"
                       @opened="changeLogo(1)"
            >
              <template v-slot:title>
                <h2 class="main-color">World Jewish Relief</h2>
              </template>
              <template v-slot:content>
                <div class="ac-content">
                  <p>
                    World Jewish Relief was founded in 1933 to rescue Jewish people fleeing from Nazi-occupied Europe
                    and support them to rebuild new lives in the UK. They have since assisted communities fleeing war,
                    persecution and natural disasters with urgent humanitarian relief and longer-term support through
                    finding employment and building independent lives in a new place.
                  </p>
                  <p>
                    World Jewish Relief launched the Specialist Training and Employment Programme (STEP) for resettled
                    refugees in the UK in 2016. STEP started in Bradford with 50 resettled refugees. Today STEP is the
                    largest provider of specialist employment support to refugees in the UK, providing support to over
                    1,500 clients annually across 23 fixed sites alongside digital delivery.

                  </p>
                </div>
              </template>
            </Accordion>

            <Accordion is-blue-arrow="true"
                       @opened="changeLogo(2)"
            >
              <template v-slot:title>
                <h2 class="main-color">TERN</h2>
              </template>
              <template v-slot:content>
                <div class="ac-content">
                  <p>TERN is a social enterprise and nonprofit founded in 2016 that supports refugees to thrive through
                    the power of their own ideas.</p>
                  <p>TERN run online entrepreneurship programmes and services accessible to refugees across the UK.</p>
                  <p>TERN also works with local partner organisations in the Southwest of England to run local startup programmes that help</p>
                </div>
              </template>
            </Accordion>

            <Accordion is-blue-arrow="true"
                       @opened="changeLogo(3)"
            >
              <template v-slot:title>
                <h2 class="main-color">The International Rescue Committee (IRC)</h2>
              </template>
              <template v-slot:content>
                <div class="ac-content">
                  <p>
                    The International Rescue Committee (IRC) helps people affected by humanitarian crises-including the
                    climate crisis--to survive, recover and rebuild their lives. Founded at the call of Albert Einstein
                    in 1933, the IRC is now at work in over 40 crisis-affected countries as well as communities
                    throughout Europe and the Americas. We deliver lasting impact by providing health care, helping
                    children learn, and empowering individuals and communities to become self-reliant, always seeking to
                    address the inequalities facing women and girls.
                  </p>
                  <p>
                    The IRC launched its first programme in the UK in 2021. The IRC works in partnership with local
                    councils to provide better integration support to refugees and vulnerable migrants living in the UK
                    from Syria, Afghanistan, Ukraine, Iraq, Sudan and beyond. Support is provided through various
                    activities, including orientation for newcomers, employment counselling, peer mentorship and
                    leadership training.
                  </p>
                </div>
              </template>
            </Accordion>
          </div>
        </div>
      </div>
    </div>

    <Modal
        :is-modal-visible="showHowItWorksModal"
        @close="closeHowItWorks"
    >
      <template v-slot:modal>
        <div class="modal-content">
          <p>
            The programme is designed through a case-working model. You will be allocated a Case Manager who will assess
            your needs and work with you to develop a tailored plan to improve your employability.</p>

          <p>Your Case Manager will review your progress on a regular basis to ensure vou are receiving the support
            needed and getting the most from the service.</p>
        </div>
      </template>
    </Modal>

    <Modal
        :is-modal-visible="showAccessibleSupportModal"
        @close="closeAccessibilityModal"
    >
      <template v-slot:modal>
        <div class="modal-content">
          <p>You can be supported by the programme for up to 18 months with the following:</p>

          <p>1. Employability Support, including self-employment and entrepreneurship: Building the skills needed to enter employment or self-employment.</p>

          <p>2. Integration support: Helping you integrate and settle into your local community</p>

          <p>3. English Language Learning: Providing formal and informal English language classes.</p>

          <p>Your participation in the programme is voluntary and vou can choose to withdraw from the programme if you wish a any point.</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Accordion from "@/components/Accordion.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: 'AboutUsPage',
  components: {Modal, Accordion},
  data() {
    return {
      showHowItWorksModal: false,
      showAccessibleSupportModal: false,
      redirectUrl: '',
      logoUrl: require('@/assets/images/REP_Logo_simplified.png'),
      logos: [
        require('@/assets/images/REP_Logo_simplified.png'),
        require('@/assets/images/world-jewish.png'),
        require('@/assets/images/TERN_Logo_Round.png'),
        require('@/assets/images/irc_arrow_square_rgb.png')
      ],
      redirectUrls: [
          '',
          'https://www.worldjewishrelief.org/',
          'https://wearetern.org/',
          'https://www.rescue.org/uk'
      ]
    }
  },
  methods: {
    showHowItWorks() {
      this.showHowItWorksModal = true;
    },
    closeHowItWorks() {
      this.showHowItWorksModal = false;
    },
    showAccessibilityModal() {
      this.showAccessibleSupportModal = true;
    },
    closeAccessibilityModal() {
      this.showAccessibleSupportModal = false;
    },
    changeLogo(index) {
      this.logoUrl = this.logos[index];
      this.redirectUrl = this.redirectUrls[index];
    },
    redirect() {
      if (this.redirectUrl.length) {
        window.open(this.redirectUrl, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background-image: url("@/assets/images/about-us-banner.png");
  height: 482px;
  position: relative;

  .corner {
    position: absolute;
    top: 0;
    width: 128px;
    @media (max-width: 1224px) {
      width: 70px;
    }
  }
  @media (max-width: 1224px) {
    background: rgba(222, 225, 218, 1);
    height: 200px;
  }
}

.banner-content {
  @media (max-width: 1224px) {
    padding: 0 16px;
    h1 {
      font-size: calc(var(--main-font-size) + 8px);
    }
  }
}

.content {
  background-color: rgba(146, 197, 235, 1);
  display: flex;
  align-items: center;
  min-height: 45vh
}

.wrapper {
  display: flex;
  gap: 24px;
  padding: 200px 0;

  .logo {
    width: 389px;
    height: 378px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 1224px) {
    flex-direction: column;
    align-items: center;
    padding: 32px 16px 120px 16px;
    .logo {
      width: 328px;
      height: 318px;
    }
  }


}

.main-color {
  color: $main-color;
}

.accordions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  h2 {
    font-weight: 600;
  }

  @media (max-width: 1224px) {
    h2 {
      font-size: calc(var(--main-font-size) + 4px);
      line-height: 25px;
      max-width: 380px;
    }
  }

  .accordion {
    background-color: white;

    ::v-deep .body {
      border-color: white;
    }
  }

  .ac-content {
    display: flex;
    flex-direction: column;
    color: black;

    p {
      margin-bottom: 20px;
      font-size: calc(var(--main-font-size) + 2px);
      line-height: 27px;
      &.ac-content-link-1 {
        cursor: pointer;
        margin-bottom: 10px;
        color: #024694;
        text-decoration: underline;
      }
      &.ac-content-link {
        cursor: pointer;
        margin-bottom: 10px;
        color: #024694;
        text-decoration: underline;

      }
    }

    a {
      color: $main-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>