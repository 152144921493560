<template>
  <div class="popover" :class="[{show: showPopover}, positionXClass, positionYClass]" :style="{ top: popoverTop + 'px', left: popoverLeft + 'px' }" @mouseenter="hidePopover()">
    <img src="@/assets/images/purple-corner.png" alt="corner" class="corner">
    <div class="content">
      <h4>{{title}}</h4>
      <ul>
        <li v-for="item in opportunities">
          {{item}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardPopover',
  props: {
    title: null,
    opportunities: null,
    showPopover: Boolean,
    popoverTop: Number,
    popoverLeft: Number,
  },
  data() {
    return {
      positionYClass: null,
      positionXClass: null
    }
  },
  methods: {
    hidePopover() {
      this.$emit('hidePopover');
    }
  }
}
</script>

<style lang="scss" scoped>

.popover {
  width: 315px;
  min-height: 150px;
  padding: 32px;
  position: absolute;
  background-color: $main-color;
  z-index: 500;
  border-radius: 32px;
  display: none;
  opacity: 0;
  //transition: opacity 0.2s ease-in-out;


  .corner {
    position: absolute;
    top: 0;
    right: 0;
  }

  .content {
    width: 230px;
    height: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: calc(var(--main-font-size) - 2px);
      font-weight: bold;
      margin: 0 auto 12px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.show {
  display: flex;
}
//.opacity {
//  opacity: 1;
//}

.bottom {
  //bottom: 50%;

  img {
    bottom: 0;
    top: unset!important;
    transform: rotate(90deg);
  }
}

.top {
  //top: 50%;
  img {
    top: 0;
    bottom: unset!important;
  }
}

.right {
  //right: 50%;

  img {
    right: 0;
  }
}

.left {
  //left: 50%;

  img {
    left: 0;
    transform: rotate(-90deg);
  }

  .content {
    align-items: flex-end;
  }
}

.left.bottom {
  justify-content: flex-end;
  img {
    transform: rotate(180deg);
  }
}

</style>