<template>
  <div v-if="showBlock" class="block">
    <div class="block-overlay" @click="closeBlock"></div>
<!--    <div class="container-search">-->
<!--      <div class="box-search">-->
<!--        <input class="search" placeholder="Search" type="text">-->
<!--        <img alt="" src="@/assets/icons/search.svg">-->
<!--      </div>-->
<!--&lt;!&ndash;      <div class="search-result" v-show="inputText !== ''">&ndash;&gt;-->
<!--&lt;!&ndash;        <p class="title">&ndash;&gt;-->
<!--&lt;!&ndash;          Search results:&ndash;&gt;-->
<!--&lt;!&ndash;        </p>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="result-list">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>Information about something important to know</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>Information about something important to know</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>Information about something important to know</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>Information about something important to know</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>Information about something important to know</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>Information about something important to know</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="list-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <p>Information about something important to know</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </div>-->

    <ul class="nav-list">
      <li @click="closeBlock()">
        <router-link class="link" to="/" >Home</router-link>
      </li>
      <li @click="closeBlock()">
        <router-link class="link" to="/about-us">About Us</router-link>
      </li>

      <li >
        <a @click="scrollToForm()" class="link">Apply today</a>
      </li>

      <li @click="closeBlock()">
        <router-link class="link" to="/refer-someone">Refer someone</router-link>
      </li>

      <li @click="closeBlock()">
        <router-link class="link" to="/news">News Us</router-link>
      </li>

      <li @click="closeBlock()">
        <router-link class="link" to="/contact">Contact Us</router-link>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'HeaderMobileMenu',
  data() {
    return {
      inputText: '',
    };
  },
  props: {
    showBlock: Boolean
  },
  methods: {
    scrollToForm() {
      if (this.$route.name !== 'home') {
        this.$router.push({path: '/'});
        setTimeout(() => {
          document.getElementById('form')?.scrollIntoView({behavior: 'smooth'})
        }, 100);
      }
      this.closeBlock();
      document.getElementById('form').scrollIntoView({behavior: 'smooth'})
    },
    closeBlock() {
      this.$emit('closeBlock');
    }
  },

}
</script>

<style scoped lang="scss">
.block {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  height: fit-content;
  border-radius: 0 0 16px 16px;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  background: #034694;
  transition: top 0.5s;
  top: 99%;
  padding: 1rem 0;

  .container-search{
    .box-search {
      position: relative;
      padding: 0 1rem;
      .search {
        width: 100%;
        padding: 1rem 1rem 1rem 3rem;
        background: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid #FFF;
        color: #fff;
        font-size: var(--main-font-size);
        font-style: normal;
        font-weight: 600;
        line-height: 125%;

        &::placeholder {
          color: rgba(255, 255, 255, 0.48);
        }
      }

      img {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(-50%, -50%);
        filter: brightness(0) invert(1);
      }
    }
    .search-result{
      padding: 3rem 1rem;
      background: #fff;
      display: flex;
      flex-direction: column;
      .title{
        color: #0A0A0A;
        font-size: var(--main-font-size);
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
      }

      .result-list{
        display: flex;
        flex-direction: column;
        max-height: 50vh;
        overflow: auto;
        .list-item{
          display: flex;
          padding: 1rem 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          gap: 1.5rem;
          p{
            color: #000;
            margin: 0;
            font-size: calc(var(--main-font-size) + 2px);
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
          .coincidences{
            color: #3B6AD0;
          }
        }
        .list-item:last-child{
          border: none;
        }
      }

    }
  }

  .body-mobile-menu {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0 4rem;


    .links {
      color: #FFF;
      font-size: calc(var(--main-font-size) + 8px);;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      margin: 0;
      text-align: center;
      text-decoration: none;
    }


  }

  .nav-list{
    text-align: center;
    padding: 0 1rem;
    li{
      list-style-type: none;
      .link{
        font-size:calc(var(--main-font-size) + 4px);;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
      }
    }
  }
}

.block-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px){
  .block{
    .header-mobile-menu{
      padding: 1.5rem 2.5rem 0;
    }
    .container-search{

      .search-result{
        padding: 2rem 2.5rem;
      }
    }
    .body-mobile-menu{
      padding: 0 2.5rem 1.5rem;
    }
  }
}
@media only screen and (max-width: 567px){
  .block{

    .header-mobile-menu{
      padding: 1.5rem 1.5rem 0;
    }
    .container-search{

      .search-result{
        padding: 2rem 1.5rem;
      }
    }
    .body-mobile-menu{
      padding: 0 1.5rem 1.5rem;
    }
  }
}
</style>