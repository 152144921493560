<template>
  <footer>
    <div class="content container mp">
      <div class="data-block">
        <div class="first-block">
          <div class="services">
            <h4 class="text-bold">Service provided by:</h4>
            <div class="images">
              <img alt="WJR" src="@/assets/images/wjr.png">

              <img alt="Tern" src="@/assets/images/tern.png">

              <img alt="IRC" src="@/assets/images/irc-big.png">
            </div>
          </div>

          <div class="terms-and-condition">
            <h4 class="text-bold">
              <a href="https://www.rescue.org/uk/respecting-your-privacy" target="_blank">Terms and Conditions</a>
            </h4>

            <p>Company Number: 00851645. Registered in the UK. Registeres Office: Academy Court. 94 Chancery Lane, London WC2A 1DT</p>

          </div>
        </div>
        <div class="links">
          <router-link class="link" to="/">Home</router-link>

          <a class="link" @click="scrollToForm">Apply as refugee</a>

          <router-link class="link" to="/refer-someone">Refer someone</router-link>

          <router-link class="link" to="/news">News</router-link>

          <router-link class="link" to="/about-us">About Us</router-link>

          <router-link class="link" to="/contact">Contact Us</router-link>
        </div>
      </div>
      <div class="founded-by">
        <img alt="Founded by" src="@/assets/images/founded-by.png">
      </div>
    </div>

    <div class="top-dots">
    </div>
    <div class="bottom-dots">
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    scrollToForm() {
      if (this.$route.name !== 'home') {
        this.$router.push({path: '/'});
        setTimeout(() => {
          document.getElementById('form')?.scrollIntoView({behavior: 'smooth'})
        }, 100);
      }
      document.getElementById('form').scrollIntoView({behavior: 'smooth'})
    }
  }
}
</script>

<style lang="scss" scoped>

.data-block {
  display: flex;
  width: calc(100% - 392px);
  gap: 158px;

  @media (max-width: 1224px) {
    width: 100%;
    gap: 0;
    flex-direction: column-reverse;
  }
}

footer {
  width: 100%;
  height: 433px;
  display: flex;
  align-items: center;
  background-color: $main-color;
  position: relative;

  .top-dots {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 31px;
    top: 0;
    background-image: url('@/assets/images/footer-dots.png');
  }
  .bottom-dots {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 31px;
    bottom: 3px;
    background-image: url('@/assets/images/footer-dots.png');
  }

  @media (max-width: 1224px) {
    height: auto;
  }
}

.content {
  height: 260px;

  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  @media (max-width: 1224px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 50px 16px;
    padding-top: 95px;
  }
}

.first-block {
  display: flex;
  width: 395px;
  flex-direction: column;

  @media (max-width: 1224px) {
    width: 100%;
    order: 1;
  }
}

.services {
  h4 {
    margin-bottom: 16px;
    text-align: center;
    @media (max-width: 1224px) {
      text-align: left;
    }
  }

  .images {
    display: flex;
    gap: 11px;

    @media (max-width: 1224px) {
      width: 100%;
      //justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
    }

    img {
      width: 124px;
      height: 65px;
      object-fit: contain;

      @media (max-width: 425px) {
        width: 124px;
        height: 65px;
      }
    }
  }
}

.terms-and-condition {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  h4 {
    text-decoration: underline;
    margin-bottom: 16px;

    a {
      color: white;
    }
  }

  p {
    font-size: calc(var(--main-font-size) - 2px);
    line-height: 21px;
  }

  @media (max-width: 1224px) {
    margin-bottom: 32px;
    align-items: start;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1224px) {
    margin-bottom: 32px;
    align-items: start;
  }
}

.founded-by {
  width: 392px;
  height: 101px;
  display: flex;

  @media (max-width: 1224px) {
    width: 100%;
    height: 85px;
    align-items: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
