<template>
  <div>
    <div @mouseover="showBlock" @mousemove="updatePosition" @mouseleave="hideBlock">
      Hover over me
    </div>
    <div class="custom-block" v-show="isBlockVisible" :style="blockStyle">
      Block content
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestPopover',
  data() {
    return {
      isBlockVisible: false,
      blockStyle: {
        top: "0px",
        left: "0px"
      }
    }
  },
  methods: {
    showBlock() {
      this.isBlockVisible = true;
    },
    hideBlock() {
      this.isBlockVisible = false;
    },
    updatePosition(event) {
      this.blockStyle.top = event.clientY + "px";
      this.blockStyle.left = event.clientX + "px";
    }
  }
}
</script>

<style scoped lang="scss">

</style>