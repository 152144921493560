<template>
<div>
  <div class="banner">
    <div class="container">
      <div class="banner-content">
        <h1 class="text-default">Leaflets</h1>
        <h1 class="text-bold">for community groups</h1>
      </div>
    </div>
    <img src="@/assets/images/green-purple-corner.svg" alt="" class="corner">
  </div>

  <div class="content">
    <div class="container">
      <div class="wrapper">
        <div class="item">
          <div class="document">
            <img src="@/assets/icons/document.svg" alt="document">
            <div class="text">
              <p>Leaflet for</p>
              <p>community groups</p>
            </div>

          </div>

          <button class="btn-secondary">
            <img alt="download" src="@/assets/icons/download.svg">
            download here
          </button>
        </div>

        <div class="item">
          <div class="document">
            <img src="@/assets/icons/document.svg" alt="document">
            <div class="text">
              <p>Leaflet for</p>
              <p>community groups</p>
            </div>

          </div>

          <button class="btn-secondary">
            <img alt="download" src="@/assets/icons/download.svg">
            download here
          </button>
        </div>

        <div class="item">
          <div class="document">
            <img src="@/assets/icons/document.svg" alt="document">
            <div class="text">
              <p>Leaflet for</p>
              <p>community groups</p>
            </div>

          </div>

          <button class="btn-secondary">
            <img alt="download" src="@/assets/icons/download.svg">
            download here
          </button>
        </div>

        <div class="item">
          <div class="document">
            <img src="@/assets/icons/document.svg" alt="document">
            <div class="text">
              <p>Leaflet for</p>
              <p>community groups</p>
            </div>

          </div>

          <button class="btn-secondary">
            <img alt="download" src="@/assets/icons/download.svg">
            download here
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'LeafletsPage'
}
</script>

<style scoped lang="scss">
.banner {
  background-image: url("@/assets/images/leaflets-banner.png");
  height: 482px;
  position: relative;

  .corner {
    position: absolute;
    top: 0;
    width: 128px;
    @media (max-width: 1224px) {
      width: 70px;
    }
  }

  @media (max-width: 1224px) {
    background: rgba(169, 219, 210, 1);
    height: 200px;
  }
}

.banner-content {
  @media (max-width: 1224px) {
    padding: 0 16px;
    h1 {
      font-size: calc(var(--main-font-size) + 8px);
    }
  }
}

.content {
  display: flex;
  align-items: center;
}

.wrapper {
  display: flex;
  gap: 32px;
  padding: 150px 0 20vh;
  flex-direction: column;
  @media (max-width: 1224px) {
    padding: 51px 16px 120px 16px;
    gap: 64px;
  }
}

.item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1224px) {
    flex-direction: column;
    gap: 24px;
  }

  .document {
    display: flex;
    gap: 16px;
    width: 260px;

    .text {
      font-size: calc(var(--main-font-size) + 2px);
      font-weight: 700;
      color: $main-color;
    }
  }

  .btn-secondary {
    width: 288px;
    font-size: calc(var(--main-font-size) + 2px);
    font-weight: 700;
    padding: 16px 32px;
    gap: 10px;
    line-height: 16px;
    border-color: black;
    color: black;
    @media (max-width: 1224px) {
      width: 100%;
    }
  }
}
</style>