<template>
  <div class="container">
    <div id="container-cards" class="opportunities-cards">
      <OpportunityCard v-for="card in opportunitiesCards"
                       :card="card"
                       @setCurrentCard="setCurrentCard($event)"
                       @mouse-stopped="showPopover"
                       @mouse-leave="hidePopover"
      >

      </OpportunityCard>
      <CardPopover
          ref="popover"
          :hide-popover="showPopover1"
          :opportunities="currentCard?.opportunities || []"
          :popoverLeft="popoverLeft"
          :popoverTop="popoverTop"
          :showPopover="showPopover1"
          :title="currentCard?.title || ''"
          @hidePopover="resetShoPopover()"
      >
      </CardPopover>


    </div>
  </div>
</template>

<script>
import OpportunityCard from "@/components/OpportunityCard.vue";
import CardPopover from "@/components/CardPopover.vue";

export default {
  name: 'NewOpportunityCard',
  components: {CardPopover, OpportunityCard},

  data() {
    return {
      opportunitiesCards: [
        {
          title: 'English language',
          icon: require('@/assets/icons/school.svg'),
          opportunities: [
            'English classes in person and online.',
            'Extra workshops for specific language needs like CV writing.',
            'Digital literacy course to help you with the basics of computers and using the internet.'
          ],
        },
        {
          title: 'Integration & Local Community',
          icon: require('@/assets/icons/distance.svg'),
          opportunities: [
            'Support to access and connect with essential services in your area.',
            'Orientation for Newcomers interactive training to get used to life in the UK.',
            'Regular check-ins to monitor and celebrate your progress.'
          ],
        },
        {
          title: 'Employment support',
          icon: require('@/assets/icons/business_center.svg'),
          opportunities: [
            'Support in accessing training, volunteering and education opportunities.',
            'Support to ensure the first few months of work go smoothly.',
          ],
        },
        {
          title: 'Job Seeking Support',
          icon: require('@/assets/icons/engineering.svg'),
          opportunities: [
            'Personalised coaching and skills identification.',
            'Creating tailored CVs and job applications.',
            'Support with searching for jobs and applications.',
            'Converting qualifications.',
            'Preparation for successful job interviews.'
          ],
        },
        {
          title: 'Self-Employment',
          icon: require('@/assets/icons/body_system.svg'),
          opportunities: [
            'Online workshops to build and test your business ideas.',
            'Online 1:1 sessions with business advisors to support on specific business questions.',
            'Startup programmes designed to help you launch a business in a specific industry, including eCommerce / Food & Beverage / Nonprofit.',
          ],
        },
      ],
      isModalVisible: false,
      currentCard: null,
      showPopover1: false,
      popoverTop: 0, // Инициализируйте переменные для координат
      popoverLeft: 0,
    }
  },
  methods: {
    setCurrentCard(card) {
      // console.log(this.currentCard)
      this.currentCard = this.opportunitiesCards.find(x => x.title === card.title)
    },

    showPopover(mouseCoordinates) {
      // console.log(this.$refs.popover.$el.computedStyleMap().get('opacity').value === 1)
      /*if (this.showPopover1) {
        return;
      }*/

      if (this.$refs.popover.$el.computedStyleMap().get('opacity').value === 1 && this.showPopover1) {
        return;
      }

      this.showPopover1 = true;

      // console.log(mouseCoordinates)

      if (this.$refs.popover) {
        const el = this.$refs.popover.$el;
        const rect = el.getBoundingClientRect();
        if (mouseCoordinates.x < 0) {
          mouseCoordinates.x = mouseCoordinates.x + 315;
          // console.log(mouseCoordinates)
          this.$refs.popover.positionXClass = 'left';
        } else {
          this.$refs.popover.positionXClass = 'right';
        }


        if (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.top <= el.clientHeight) {
          // mouseCoordinates.y = mouseCoordinates.y + el.clientHeight;
          // console.log(mouseCoordinates)
          this.$refs.popover.positionYClass = 'top';
        } else {
          mouseCoordinates.y = mouseCoordinates.y - el.clientHeight;
          this.$refs.popover.positionYClass = 'bottom';
        }

        /*if (rect.right <= (window.innerWidth || document.documentElement.clientWidth) && rect.left <= el.clientWidth) {
          this.$refs.popover.positionXClass = 'left'
          mouseCoordinates.x = mouseCoordinates.x + el.clientWidth;
        } else {
          this.$refs.popover.positionXClass = 'right';
        }

        if (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.top <= el.clientHeight) {
          this.$refs.popover.positionYClass = 'top';
        } else {
          mouseCoordinates.y = mouseCoordinates.y - el.clientHeight;
          this.$refs.popover.positionYClass = 'bottom';
        }*/



        setTimeout(() => {
          el.style.opacity = 1
        }, 300)
      }
      // console.log('Mouse coordinates:', mouseCoordinates.x, mouseCoordinates.y);
      this.popoverTop = mouseCoordinates.y; // Установите координаты из события
      this.popoverLeft = mouseCoordinates.x;
    },

    hidePopover() {

      setTimeout(() => {
      }, 4000)

      this.$refs.popover.$el.style.opacity = 0;

      // console.log('hide')


    },
    resetShoPopover() {

    }
  }

}
</script>

<style lang="scss" scoped>
.opportunities-cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 120px;

  @media (max-width: 1224px) {
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
  }
}
</style>