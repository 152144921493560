<template>
  <header class="header">
    <div class="container">
      <BreadCrumbs/>

      <div class="nav">
        <div class="founded-by" @click="hideBlock">
          <img alt="Founded By" class="logo" src="@/assets/images/founded-by.png">
        </div>

        <router-link class="rep-program" to="/" >
          <img alt="REP" class="logo" src="@/assets/images/rep-logo.png" @click="hideBlock">
        </router-link>

        <ul class="nav-list">
          <li @click="hideBlock">
            <router-link class="link" to="/about-us">About Us</router-link>
          </li>

          <li>
            <a class="link" @click="scrollToForm">Apply today</a>
          </li>

          <li>
            <router-link class="link" to="/refer-someone">Refer someone</router-link>
          </li>

          <li>
            <router-link class="link" to="/news">News Us</router-link>
          </li>

          <li>
            <router-link class="link" to="/contact">Contact Us</router-link>
          </li>
        </ul>

        <div class="additional-wrapper">
<!--          <a class="search" @click="toggleAdvancedSearch">-->
<!--            <img alt="Search" src="@/assets/icons/search.svg">-->
<!--          </a>-->

          <div class="language-select">
            <div class="selected" @click="toggleLanguagesDropdown">
              <img alt="" src="@/assets/icons/language.svg">
              {{ selectedLanguage }}
              <img alt="" src="@/assets/icons/expand_more.svg">
            </div>


            <div v-if="dropdownIsOpen" class="options">
              <a v-if="selectedLanguage !== 'Eng'"
                 class="option"
                 @click="changeLanguage('Eng')"
              >
                <img alt="" src="@/assets/icons/language.svg">
                Eng
              </a>

              <a v-if="selectedLanguage !== 'Dari'"
                 class="option"
                 @click="changeLanguage('Dari')"
              >
                <img alt="" src="@/assets/icons/language.svg">
                Dari
              </a>

              <a v-if="selectedLanguage !== 'Arabic'"
                 class="option"
                 @click="changeLanguage('Arabic')"
              >
                <img alt="" src="@/assets/icons/language.svg">
                Arabic
              </a>

              <a v-if="selectedLanguage !== 'Pashto'"
                 class="option"
                 @click="changeLanguage('Pashto')"
              >
                <img alt="" src="@/assets/icons/language.svg">
                Pashto
              </a>
            </div>
          </div>

          <div class="irc">
            <img alt="IRC" class="logo" src="@/assets/images/irc-logo.png">
          </div>

          <div class="person-logo" @click="toggleAccessibilityTools">
            <img alt="person" class="logo" src="@/assets/images/person.png">
          </div>

          <button v-if="!isBlockVisible" class="burger" @click="showBlock">
            <img alt="burger" src="@/assets/icons/burger.svg">
          </button>

          <button v-else class="burger" @click="hideBlock">
            <img alt="" src="@/assets/icons/close.svg">
          </button>

        </div>
      </div>
    </div>

<!--    <div v-if="advancedSearchIsOpen" class="advanced-search container">-->
<!--      <div class="search-input">-->
<!--        <img alt="Search" src="@/assets/icons/search.svg">-->
<!--        <input placeholder="Search">-->
<!--        <a class="search" @click="toggleAdvancedSearch">-->
<!--          <img alt="Close" src="@/assets/icons/close.svg">-->
<!--        </a>-->
<!--      </div>-->

<!--      <h4 class="text-bold">Search-results:</h4>-->

<!--      <div class="search-results">-->
<!--        <div class="result">-->
<!--          <h2>Information about something important to know</h2>-->
<!--          <h2>Article</h2>-->
<!--        </div>-->

<!--        <div class="result">-->
<!--          <h2>Information about something important to know</h2>-->
<!--          <h2>Article</h2>-->
<!--        </div>-->

<!--        <div class="result">-->
<!--          <h2>Information about something important to know</h2>-->
<!--          <h2>Article</h2>-->
<!--        </div>-->

<!--        <div class="result">-->
<!--          <h2>Information about something important to know</h2>-->
<!--          <h2>Article</h2>-->
<!--        </div>-->

<!--        <div class="result">-->
<!--          <h2>Information about something important to know</h2>-->
<!--          <h2>Article</h2>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <AccessibilityTools :show-accessibility-tools="showAccessibilityTools" @closeAccessibilityTools="toggleAccessibilityTools()"/>
    <header-mobile-menu :showBlock="isBlockVisible" @closeBlock="hideBlock"/>

  </header>
</template>

<script>
import Slider from "@/components/Slider.vue";
import HeaderMobileMenu from "@/components/HeaderMobileMenu.vue";
import AccessibilityTools from "@/components/AccessibilityTools.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default {
  name: 'Header',
  components: {BreadCrumbs, AccessibilityTools, Slider, HeaderMobileMenu},

  data() {
    return {
      selectedLanguage: 'Eng',
      dropdownIsOpen: false,
      advancedSearchIsOpen: false,
      showAccessibilityTools: false,
      isBlockVisible: false,
    }
  },

  methods: {
    showBlock() {
      this.isBlockVisible = true;
      this.showAccessibilityTools = false;
    },

    hideBlock() {
      this.isBlockVisible = false;
    },

    changeLanguage(language) {
      this.selectedLanguage = language;
      this.dropdownIsOpen = false;
    },

    toggleLanguagesDropdown() {
      this.dropdownIsOpen = !this.dropdownIsOpen
    },

    toggleAdvancedSearch() {
      this.advancedSearchIsOpen = !this.advancedSearchIsOpen
    },

    toggleAccessibilityTools() {
      this.hideBlock();
      this.showAccessibilityTools = !this.showAccessibilityTools;
    },

    scrollToForm() {
      this.hideBlock();
      if (this.$route.name !== 'home') {
        this.$router.push({path: '/'});
        setTimeout(() => {
          document.getElementById('form')?.scrollIntoView({behavior: 'smooth'})
        }, 100);
      }
      document.getElementById('form')?.scrollIntoView({behavior: 'smooth'})
    },
  },

  created() {
    window.addEventListener('keydown', (event) => {
      if (event.keyCode === 85 && event.ctrlKey) {
        event.preventDefault();
        this.toggleAccessibilityTools();
      }
    });
  },

  watch: {
    $route() {
      this.isBlockVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 94px;
  background-color: $main-color;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 1224px) {
    padding: 0 16px;
  }
}

.nav {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.founded-by {
  width: 145px;
  height: 37px;
  @media (max-width: 1224px) {
    width: 97px;
    height: 24px;
  }
}

.rep-program {
  width: 166px;
  height: 37px;
  @media (max-width: 1224px) {
    width: 112px;
    height: 24px;
  }
}

.nav-list {
  display: flex;
  width: 525px;
  gap: 24px;
  list-style: none;

  li {
    a {
      text-decoration: none;
    }
  }

  @media (max-width: 1224px) {
    display: none;
  }

}

.additional-wrapper {
  width: 278px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1224px) {
    width: 80px;
  }
}

.search {
  transition: opacity .2s ease-in-out;

  @media (max-width: 1224px) {
    display: none;
  }

  &:hover {
    opacity: .8;
    cursor: pointer;
  }
}

.language-select {
  color: #ffffff;
  display: flex;
  width: 100px;
  align-items: center;
  font-size: var(--main-font-size);
  font-weight: 600;
  gap: 4px;
  position: relative;

  @media (max-width: 1224px) {
    display: none;
  }

  .selected {
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: opacity .2s ease-in-out;

    &:hover {
      opacity: .8;
      cursor: pointer;
    }
  }

  .options {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $main-color;
    width: 100px;
    bottom: -125px;
    left: -7px;
    z-index: 10;
  }

  .option {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border-bottom: 1px solid #ffffff;
    transition: opacity .2s ease-in-out;

    &:hover {
      cursor: pointer;
      opacity: .8;
    }

    &:last-child {
      border: none;
    }
  }
}

.person-logo {
  width: 40px;
  height: 40px;

  &:hover {
    cursor: pointer;
  }
}

.irc {
  width: 33px;
  height: 44px;
  @media (max-width: 1224px) {
    display: none;
  }
}

.advanced-search {
  position: absolute;
  height: 470px;
  padding: 16px 32px;
  background-color: $main-color;
  color: #ffffff;
  top: 94px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.search-input {
  height: 42px;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;

  input {
    height: 100%;
    background: none;
    border: none;
    outline: none;
    width: 95%;
    color: white;
    font-size: var(--main-font-size);
  }
}

.search-results {
  display: flex;
  flex-direction: column;
  height: 330px;
  overflow-y: auto;

  .result {
    border-bottom: 1px solid #ffffff24;
    padding: 10px 0;
  }
}

.burger {
  display: none;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  @media (max-width: 1224px) {
    display: block;
  }
}


</style>