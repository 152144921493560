<template>
  <div class="form-container">
    <div class="container">
      <div class="wrapper">
        <div class="employ-info">
          <h1>
            The Refugee Employability Programme is here to help if you:
          </h1>

          <ul>
            <li>
              <img alt="done" src="@/assets/icons/done_all.svg">
              <p>Live in the South West</p>
            </li>

            <li>
              <img alt="done" src="@/assets/icons/done_all.svg">
              <p>Between the age of 18 and 66 (state pension age)</p>
            </li>

            <li>
              <img alt="done" src="@/assets/icons/done_all.svg">
              <p>Have either Indefinite Leave to Remain, also known as settled status,
                or be on the 5 year pathway to settled status.</p>
            </li>

            <li>
              <img alt="done" src="@/assets/icons/done_all.svg">
              <p>You must have arrived in the UK by certain routes, not including Homes for Ukraine
                <a @click="openModal">(click here for more details)</a>
                <Modal
                    :is-modal-visible="isModalVisible"
                    @close="closeModal"
                >
                  <template v-slot:modal>
                    <div class="modal-content">
                      <div class="block">
                        <p>
                          You must have arrived in the UK under one of the following routes:
                        </p>

                        <ul>
                          <li>
                            UK Resettlement Scheme (UKRS)
                          </li>
                          <li>
                            Afghan Citizen's Resettlement Scheme (ACRS).
                          </li>
                          <li>
                            Afghan Relocations and Assistance Policy (ARAP)
                          </li>
                          <li>
                            Community Sponsorship Scheme (if you arrived on or after 25 February 2021)
                          </li>
                        </ul>
                      </div>

                      <div class="block">
                        <p>You can get extra support focused on Integration and English language learning if you
                          arrived
                          through the below routes:</p>

                        <ul>
                          <li>Refugee Permission to Stay (if you were granted Permission to Stay on or after 28 June
                            2022)
                          </li>
                          <li>Mandate Resettlement Scheme (if you arrived on or after 25 February 2021))</li>
                          <li>Refugee Family Reunion (if your sponsoring refugee is eligible)</li>
                        </ul>
                      </div>

                      <div class="block">
                        <p>
                          For more information, please refer to the UK Government REP page, or contact us:
                        </p>
                        <p>
                          Email: REP.SW@rescue.org
                        </p>
                        <p>
                          Free telephone: 0808 294 0124
                        </p>
                      </div>

                    </div>
                  </template>
                </Modal>
              </p>
            </li>

            <li>
              <img alt="done" src="@/assets/icons/done_all.svg">
              <p>You are actively seeking work and not in full time education or employment.</p>
            </li>
          </ul>

        </div>

        <div class="form-wrapper">
          <JotFormComponent/>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import JotFormComponent from "@/components/JotFormComponent.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: 'FormBlock',
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },
  components: {
    Modal,
    JotFormComponent
  }
}
</script>

<style scoped lang="scss">
.form-container {
  width: 100%;
  min-height: 687px;
  background-color: #92C5EB;
  padding: 44px 0;

  .form-wrapper {
    width: 59%;
    height: 900px;

    @media (max-width: 1224px) {
      width: 100%;
      height: 900px;
    }
  }

  @media (max-width: 1224px) {
    padding: 64px 16px;
  }
}
.employ-info {
  width: 41%;

  h1 {
    font-size: calc(var(--main-font-size) + 32px);
    font-weight: 700;
    line-height: 124%;
    color: $main-color;
  }

  ul {
    width: 70%;
    list-style: none;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 1224px) {
      width: 100%;
    }
  }

  li {
    font-size: var(--main-font-size);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;

    a {
      color: $main-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 1224px) {
    width: 100%;
    margin-bottom: 64px;

    h1 {
      font-size: calc(var(--main-font-size) + 8px);
    }
  }

}
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1224px) {
    flex-direction: column;
  }
}
</style>