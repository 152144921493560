<template>
  <div class="container content">
    <div class="filter-group">
      <SelectComponent class="filter" :options="optionsCategories"/>
    </div>
    <div class="box-news">
      <div v-for="(newsSubject, index) in news" class="news">
        <img alt="" :src="getFile(newsSubject.logo)">
        <div class="news-info">
          <p class="publication-date">
            Tuesday, August 1, 2023
          </p>
          <p class="title">
            {{newsSubject.title}}
          </p>
          <p class="description">
            {{newsSubject.description}}
          </p>
        </div>
        <blue-button class="blue-button" :title="'Read more'" @click="navigateToNewsReadMore(newsSubject.url)"/>
      </div>
    </div>
  </div>
</template>

<script>
import SelectComponent from "@/components/SelectComponent.vue";
import BlueButton from "@/components/BlueButton.vue";
import axios from "axios";

export default {
  name: 'NewsPage',
  components: {BlueButton, SelectComponent},
  data() {
    return {
      news: [],
      optionsCategories: [
        {value: "option1", text: "All Years"},
        {value: "option2", text: "2023"},
        // {value: "option3", text: "Option 2"},
        // {value: "option4", text: "Option 3"},
      ],
    };
  },
  methods: {
    navigateToNewsReadMore(url) {
      this.$router.push({path: `/read-more/${url}`});
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },

    getFile(url){
      return process.env.VUE_APP_API_URL + url
    }
  },

  mounted() {
    axios.get(process.env.VUE_APP_API_URL+"/news")
        .then((response)=>{
          this.news = response.data
        })
  }
}
</script>

<style scoped lang="scss">
.container {
  @media (max-width: 1224px) {
    padding: 0 1rem;
  }
}

.content {
  min-height: 100vh;
}

.filter-group {
  margin: 4rem 0 1.5rem;
  display: flex;
  gap: 5px;

  .filter {
    width: 392px;
    @media (max-width: 1224px) {
      width: 100%;
    }
  }
}

.box-news {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem 1rem;
  margin-bottom: 8rem;

  .news {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    img {
      width: 100%;
      height: 100%;
    }

    .news-info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .publication-date {
        color: #373737;
        font-size: calc(var(--main-font-size) - 2px);
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .title {
        color: #184892;
        font-size: calc(var(--main-font-size) + 2px);
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }

      .description {
        color: #000;
        font-size: var(--main-font-size);
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }


  }

  .blue-button {
    width: 143px;
    font-size: var(--main-font-size);
    padding: 8px 20px;
    font-weight: 400;
    text-transform: none!important;
  }
}

@media only screen and (max-width: 768px) {

  .box-news {
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
  }
}

</style>