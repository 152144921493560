<template>
  <div>
    <div class="banner">
      <div class="container">
        <div class="banner-wrapper">
          <div class="mobile-banner">
            <img src="@/assets/images/green-purple-corner.svg" alt="" class="corner">
          </div>
          <div class="banner-content">
            <h1 class="text-default">Welcome to the</h1>
            <h1 class="text-bold-700">Refugee Employability</h1>
            <h1 class="text-bold-700">Programme</h1>

            <p class="text-bold">Preparing refugees for work and life in South-West England</p>

            <div class="buttons">
              <button class="btn-primary" @click="scrollToForm">Apply today</button>
              <router-link class="btn-secondary" to="/refer-someone">refer someone</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="languages">
        <div class="language-block">
          <label class="language-tile">
            <span>English language</span>

            <input checked class="radio-btn" name="language" type="radio">
          </label>
          <label class="language-tile">
            <span>پښتو (Pashto)</span>

            <input class="radio-btn" name="language" type="radio">
          </label>
        </div>

        <div class="language-block">
          <label class="language-tile">
          <span>اَلْعَرَبِيَّةُ
            (Arabic)</span>

            <input class="radio-btn" name="language" type="radio">
          </label>
          <label class="language-tile">
          <span>درى
            (Dari)</span>

            <input class="radio-btn" name="language" type="radio">
          </label>
        </div>


      </div>

    </div>

    <div class="container">
      <div class="map-content">
        <img alt="Map" class="map-image" src="@/assets/images/map.png">

        <div class="map-info">
          <h1>Preparing refugees for work and life in England</h1>

          <p>
            Get individual support and a learning plan from your own Case Manager so you can improve your English and
            career skills.
          </p>

          <p class="text-black">
            The Refugee Employability Programme can help you with:
          </p>

          <div class="info-block">
            <div class="icons">
              <img alt="done" src="@/assets/icons/done_all.svg">
              Finding employment:
            </div>
            <div>
              Build your skills to find meaningful work, including self-employment.
            </div>
          </div>

          <div class="info-block">
            <div class="icons">
              <img alt="done" src="@/assets/icons/done_all.svg">
              Settling in:
            </div>
            <div>
              Learn how to navigate UK life independently.
            </div>
          </div>

          <div class="info-block">
            <div class="icons">
              <img alt="done" src="@/assets/icons/done_all.svg">
              Learning English:
            </div>
            <div>
              Build confidence to communicate better.
            </div>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="container">-->
<!--      <div class="opportunities-cards">-->
<!--        <OpportunityCard v-for="card in opportunitiesCards"-->
<!--                         :card="card"-->
<!--        >-->
<!--        </OpportunityCard>-->

<!--&lt;!&ndash;        <test-popover/>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
    <new-opportunity-card/>


    <FormBlock id="form"/>

    <div class="work-program">
      <div class="container">
        <div class="wrapper">
          <div class="how-works">
            <h1>How the
              programme works
            </h1>
            <p>
              Here is how the Refugee Employability Programme works and what we can do to support you.
            </p>

            <img alt="Program" src="@/assets/images/program-works.png">
          </div>

          <div class="accordions">
            <Accordion :opened="true">
              <template v-slot:title>
                <h4><span>1</span> Check your eligibility</h4>
              </template>
              <template v-slot:content>
                <p>- Between the age of 18 and 66 (state pension age).</p>
                <p>- Living in the South West of England.</p>
                <p>- Actively seeking work and not in full-time employment (16+ hours/week) or full-time education.</p>
                <p>- Must either have Indefinite Leave to Remain, also known as settled status, or be on the 5 year
                  pathway to settled status.</p>
                <p>- You must have arrived in the UK by certain routes (click here for details)</p>
              </template>
            </Accordion>

            <Accordion>
              <template v-slot:title>
                <h4><span>2</span> Meet your case worker</h4>
              </template>
              <template v-slot:content>
                <p>
                  Your assigned Case Manager will arrange an introduction meeting in the next 10 working days to
                  understand your goals, assess your needs and discuss the support that can be provided.
                </p>

                <p>You can meet with them in person (depending where you live). online or over the phone.</p>

                <p>Your Case manager will be supporting you during the programme duration. They will be vour main point
                  of
                  contact.</p>

                <p>In order to better support you, your case manager will need to understand your strengths, potential
                  barriers and your goals. They will also assess your level of English.</p>
              </template>
            </Accordion>

            <Accordion>
              <template v-slot:title>
                <h4><span>3</span> Set your goals together</h4>
              </template>
              <template v-slot:content>
                <p>
                  Work with your case manager your personal development plan. Set your short term and longer term goals.
                  Agree on actions/steps needed that you need to reach them.
                </p>
                <p>You will agree with your case managers on your next milestones and the support needed to get
                  there.</p>
                <p>Build together with your case manager a tailored Personal Development Plan to outline the next
                  steps.</p>
              </template>
            </Accordion>

            <Accordion>
              <template v-slot:title>
                <h4><span>4</span> Access support needed</h4>
              </template>
              <template v-slot:content>
                <p>
                  The personalised Personal Development Plan will outline what support you need to access in order to
                  overcome different barriers, improve your English level, find a job and feel more settled.
                </p>
                <p>
                  Your plan will include the services that can be accessed internally and the external referrals made.
                </p>
              </template>
            </Accordion>

            <Accordion>
              <template v-slot:title>
                <h4><span>5</span> Regular check-in on progress</h4>
              </template>
              <template v-slot:content>
                <p>
                  Your case manager will contact you at least every 10 working days to check in on your progress,
                  celebrate your achievements and identify additional support needed.
                </p>
                <p>
                  We can support you for a period up to 18 months even after you find a job, they can still continue to
                  support you for 6 month. This could include in-work support for up to 6 months
                </p>
              </template>
            </Accordion>

            <button class="btn-primary" @click="scrollToForm()">apply today</button>

          </div>

        </div>
      </div>
    </div>


    <div class="download-resources">
      <div class="container">
        <div class="wrapper">
          <div class="info">
            <h1>Downloadable Resources</h1>
            <p>Click here to access flyers about the project and other materials
              and resources.</p>

            <router-link class="btn-secondary" to="/leaflets">
              <img alt="download" src="@/assets/icons/download.svg">
              download here
            </router-link>
          </div>
          <img alt="download" class="download-image" src="@/assets/images/download.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Accordion from "@/components/Accordion.vue";
import OpportunityCard from "@/components/OpportunityCard.vue";
import FormBlock from "@/components/FormBlock.vue";
import {toRefs} from "vue";
import TestPopover from "@/components/TestPopover.vue";
import NewOpportunityCard from "@/components/NewOpportunityCard.vue";
import JotFormComponent from "@/components/JotFormComponent.vue";

export default {
  name: 'Home',
  data() {
    return {
      opportunitiesCards: [
        {
          title: 'English language',
          icon: require('@/assets/icons/school.svg'),
          opportunities: [
            'English classes in person and online.',
            'Extra workshops for specific language needs like CV writing.',
            'Digital literacy course to help you with the basics of computers and using the internet.'
          ],
        },
        {
          title: 'Integration & Local Community',
          icon: require('@/assets/icons/distance.svg'),
          opportunities: [
            'Support to access and connect with essential services in your area.',
            'Orientation for Newcomers interactive training to get used to life in the UK.',
            'Regular check-ins to monitor and celebrate your progress.'
          ],
        },
        {
          title: 'Employment support',
          icon: require('@/assets/icons/business_center.svg'),
          opportunities: [
            'Support in accessing training, volunteering and education opportunities.',
            'Support to ensure the first few months of work go smoothly.',
          ],
        },
        {
          title: 'Job Seeking Support',
          icon: require('@/assets/icons/engineering.svg'),
          opportunities: [
            'Personalised coaching and skills identification.',
            'Creating tailored CVs and job applications.',
            'Support with searching for jobs and applications.',
            'Converting qualifications.',
            'Preparation for successful job interviews.'
          ],
        },
        {
          title: 'Self-Employment',
          icon: require('@/assets/icons/body_system.svg'),
          opportunities: [
            'Online workshops to build and test your business ideas.',
            'Online 1:1 sessions with business advisors to support on specific business questions.',
            'Startup programmes designed to help you launch a business in a specific industry, including eCommerce / Food & Beverage / Nonprofit.',
          ],
        },
      ],
      isModalVisible: false
    }
  },
  components: {
    NewOpportunityCard,
    TestPopover,
    FormBlock,
    OpportunityCard,
    Accordion,
    JotFormComponent
  },
  methods: {
    scrollToForm() {
      document.getElementById('form').scrollIntoView({
        behavior: 'smooth'
      });
    }
  },
}
</script>

<style lang="scss" scoped>

.banner {
  @media (max-width: 1224px) {
    background: none;
    height: auto;
  }
}

.language-block {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1224px) {
    gap: 5px;
    width: calc(50% - 2.5px);
  }
}

.banner-wrapper {
  display: flex;
  flex-direction: column;

  .mobile-banner {
    display: none;
    position: relative;
    width: 100%;
    height: auto;
    background-image: url("@/assets/images/ZahraRahimi.jpg");
    min-height: 400px;
    max-height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    .corner {
      width: 128px;
      position: absolute;
      top: 0;
      @media (max-width: 770px) {
        width: 70px;
      }
    }
    img {
      width: 100%;
      max-height: 600px;
      object-fit: cover;
      object-position: top;
    }

    @media (max-width: 1224px) {
      display: flex;
    }
  }

  .banner-content {
    a {
      text-decoration: none;
    }

    .buttons {
      button, a {
        font-size: calc(var(--main-font-size) + 2px);
      }
    }

    @media (max-width: 1224px) {
      background: rgba(146, 197, 235, 1);
      padding: 16px;

      h1 {
        font-size: calc(var(--main-font-size) + 8px);
      }

      p {
        font-size: var(--main-font-size);
      }

      .buttons {
        margin-top: 32px;
        flex-direction: column;

        button, a {
          width: 100%;
          font-size: var(--main-font-size);
          padding: 15px 20px;

        }
      }
    }
  }
}

.languages {
  margin: 48px 0 120px 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 16px;


  .language-tile {
    width: 600px;
    font-weight: 600;
    height: 91px;
    border-radius: 8px;
    padding: 32px;
    background-color: rgba(169, 219, 210, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: var(--main-font-size);
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1224px) {
    padding: 0 16px;
    margin: 24px 0;
    gap: 5px;
    justify-content: center;

    .language-tile {
      width: 100%;
      min-height: 40px;
      height: fit-content;
      padding: 10px;

      span {
        font-size: calc(var(--main-font-size) - 3px);
      }
    }
  }

  @media (max-width: 375px) {
    padding: 0!important;
  }
}

.map-content {
  display: flex;
  margin-bottom: 120px;

  .map-info {
    padding: 0 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h1 {
      font-weight: 700;
      font-size: calc(var(--main-font-size) + 32px);
      color: $main-color;
      line-height: 124%;
    }

    p {
      font-size: calc(var(--main-font-size) + 2px);
      line-height: 27px;
      color: $main-color;

      &.text-black {
        color: #000000;
      }
    }

    .info-block {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .icons {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
      }
    }

    @media (max-width: 1224px) {
      padding: 0;
      h1 {
        font-size: calc(var(--main-font-size) + 8px);
      }

      p {
        font-size: var(--main-font-size);
      }
    }
  }

  @media (max-width: 1224px) {
    flex-direction: column;
    padding: 0 16px;
    align-items: center;

    .map-image {
      width: 328px;
      height: 328px;
      margin-bottom: 24px;
    }
  }
}

.opportunities-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 120px;

  @media (max-width: 1224px) {
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
  }
}

.work-program {
  height: 983px;
  display: flex;
  align-items: center;
  overflow: auto;

  .how-works {
    width: 32%;
    color: $main-color;
    font-weight: 700;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: calc(var(--main-font-size) + 32px);
      margin-bottom: 13px;
      line-height: 124%;
    }

    p {
      font-size: calc(var(--main-font-size) + 2px);
      margin-bottom: 30px;
      font-weight: 400;
    }

    @media (max-width: 1224px) {
      width: 100%;
      margin-bottom: 32px;

      h1 {
        font-size: calc(var(--main-font-size) + 8px);
      }

      p {
        font-size: var(--main-font-size);
      }

      img {
        width: 322px;
        height: 371px;
        align-self: center;
      }
    }
  }

  .accordions {
    width: 66%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    h4 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
      letter-spacing: -0.18px;
      @media (max-width: 720px) {
        font-size: 16px;
      }
      span {
        font-size: 24px;
        padding-right: 16px;
        @media (max-width: 720px) {
          font-size: 20px;
        }
      }
    }
    @media (max-width: 1224px) {
      width: 100%;

      .btn-primary {
        width: 100%!important;
      }
    }

    .btn-primary {
      width: 392px;
    }
  }

  @media (max-width: 1224px) {
    height: auto;
    padding: 120px 16px;

    .wrapper {
      flex-direction: column;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.download-resources {
  height: 467px;
  background-color: #A9DBD2;
  display: flex;
  align-items: center;

  @media (max-width: 1224px) {
    padding: 32px 16px;
    height: auto;
    .wrapper {
      flex-direction: column-reverse;
      align-items: center;
    }
    .download-image {
      width: 122px;
      height: 140px;
    }
  }

  .info {
    h1 {
      font-size: calc(var(--main-font-size) + 32px);
      font-weight: 700;
      margin-bottom: 16px;
    }

    p {
      font-size: calc(var(--main-font-size) + 2px);
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 32px;
    }

    @media (max-width: 1224px) {
      h1 {
        margin-top: 32px;
        font-size: calc(var(--main-font-size) + 8px);
      }
    }
  }

  .btn-secondary {
    width: 60%;
    font-size: calc(var(--main-font-size) + 2px);
    font-weight: 700;
    padding: 16px 90px;
    gap: 10px;
    line-height: 16px;
    border-color: black;
    color: black;
    text-decoration: none;
    @media (max-width: 1224px) {
      width: 100%;
      font-size: calc(var(--main-font-size) + 2px);
      padding: 16px 40px;
    }
  }
}

</style>