<template>
  <div class="container">
    <div class="container-all-news">
      <div class="news-header">
        <img alt="" :src="getFile(news.logo)">
        <p class="title">
          <span>September 18, 2023</span>
          International Rescue Committee launches programme preparing refugees for work and life in South West England.
        </p>
      </div>
      <div class="body-news" v-html="cleanHtml(news.body)">

      </div>
<!--      <div class="chapter">-->
<!--        <p class="chapter-title">-->
<!--          London, United Kingdom, September 18, 2023-->
<!--        </p>-->
<!--        <p class="chapter-text">-->
<!--          The International Rescue Committee (IRC), in partnership with World Jewish Relief and The Entrepreneurial-->
<!--          Refugee Network (TERN), is delighted to announce the launch of the Refugee Employability Programme (REP)-->
<!--          funded by the UK Government, in the South West region of England. This new initiative will provide vital-->
<!--          support and services to refugees settling in the area to help prepare them for work and life in their new-->
<!--          communities across Bristol, Dorset, Somerset, Devon, Wiltshire, Cornwall, Gloucestershire, South-->
<!--          Gloucestershire, Plymouth, Bath & North East Somerset, North Somerset, Swindon, Torbay, Bournemouth,-->
<!--          Christchurch and Poole.<br>-->

<!--          <br>We know that when refugees are welcomed, they can thrive. Yet many refugees still face significant-->
<!--          challenges-->
<!--          as they rebuild their lives after arriving safely in the UK. Finding a job, getting an education, learning the-->
<!--          language and accessing basic services such as the NHS can all be challenging. The REP programme is here to-->
<!--          help with that, working with over 700 refugees who have overcome tremendous difficulties to resettle in their-->
<!--          new communities in the Southwest.<br>-->

<!--          <br>The launch of the programme builds upon the IRC’s offerings in other regions of the UK, where the-->
<!--          organisation-->
<!--          has supported over 800 refugees, providing integration, employment, leadership and mentoring courses. The-->
<!--          programme also draws upon the expertise of partner organisations World Jewish Relief and The Entrepreneurial-->
<!--          Refugee Network (TERN); World Jewish Relief is the largest provider of specialist employment support to-->
<!--          refugees in the UK, and will this year provide support to more than 10,000 refugees through its Specialist-->
<!--          Training and Employment Programmes (STEP). TERN, founded in 2016, run online entrepreneurship programmes and-->
<!--          services accessible to refugees across the UK, supporting them to thrive through the power of their own ideas.-->
<!--          The Refugee Employability Programme offers one-to-one support and tailored employment action plans with the-->
<!--          help of employment and integration advisors. Support includes help with job searches, CV and cover letter-->
<!--          writing and interview preparation. Participants will also have the opportunity to develop their understanding-->
<!--          of the UK job market, through work experience and volunteering.<br>-->

<!--          <br>In addition, the programme provides English language support, in the form of language classes and literacy-->
<!--          lessons, as well as informal conversation clubs giving people the opportunity to practice their newly-->
<!--          developed skills. The REP programme will also support refugees through their integration into local-->
<!--          communities, connecting them with vital services, such as healthcare, childcare, and community groups.<br>-->

<!--          <br>The support offered by the Refugee Employability Programme will play a vital role in empowering refugees-->
<!--          based-->
<!--          across the South West of England with the confidence and knowledge they need to secure jobs, improve language-->
<!--          skills and integrate into their communities.-->
<!--        </p>-->
<!--      </div>-->
<!--      <img alt="" src="@/assets/images/news/news-2.png">-->
<!--      <div class="chapter">-->
<!--        <p class="chapter-title">-->
<!--          Genevieve Caston, Director of UK RAI at the IRC, said,-->
<!--        </p>-->
<!--        <p class="chapter-text">-->
<!--          “We are thrilled to announce this new programme, in partnership with World Jewish Relief, and The-->
<!--          Entrepreneurial Refugee Network to support refugees and asylum seekers to successfully integrate and thrive in-->
<!--          communities across South West England.<br>-->
<!--          By breaking down employment barriers, building critical language skills, and equipping participants with the-->
<!--          knowledge to navigate life in the UK, we can empower refugees to fully participate in the workforce and their-->
<!--          local communities.<br>-->

<!--          <br>With the right support refugees can secure independent and rewarding livelihoods, and thrive in local-->
<!--          communities. In turn, this allows the local community to benefit from the diverse strengths and contributions-->
<!--          of new arrivals.”-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="chapter">-->
<!--        <p class="chapter-title">-->
<!--          Paul Anticoni, CEO of World Jewish Relief, said,-->
<!--        </p>-->
<!--        <p class="chapter-text">-->
<!--          “Since launching our specialist training and employment programmes nearly a decade ago, we’ve worked with-->
<!--          thousands of refugees in the UK, supporting them to develop essential skills and qualifications to improve-->
<!--          their integration into the UK. We know this can make a vast difference to people’s lives. Fortunately, this-->
<!--          new partnership will enable hundreds more people in the South West to benefit, and we’re excited to be working-->
<!--          alongside the IRC and TERN to deliver this vital programme.”-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="chapter">-->
<!--        <p class="chapter-title">-->
<!--          Charlie Fraser, Partnerships & Strategy Director at TERN, said,-->
<!--        </p>-->
<!--        <p class="chapter-text">-->
<!--          "This exciting new partnership will enable us to provide holistic support to newcomers from refugee-->
<!--          backgrounds in the South West. We look forward to connecting aspiring entrepreneurs with our range of-->
<!--          pre-incubation and incubation programmes, which will enable them to explore routes to sustainable self-->
<!--          employment through entrepreneurship."<br>-->

<!--          <br>The Refugee Employability Programme is currently accepting applications. To find out more information, see-->
<!--          who-->
<!--          is eligible to take part and submit an application, please see: <span><a>https://www.rescue.org/uk/repsw</a></span>-->
<!--        </p>-->
<!--      </div>-->
<!--      <img alt="" src="@/assets/images/news/news-3.png">-->
<!--      <div class="chapter">-->
<!--        <p class="chapter-title">-->
<!--          Note to Editors-->
<!--        </p>-->
<!--        <p class="chapter-title">-->
<!--          About the IRC:-->
<!--        </p>-->
<!--        <p class="chapter-text">-->
<!--          The International Rescue Committee helps people affected by humanitarian crises—including the climate-->
<!--          crisis—to survive, recover and rebuild their lives. Founded at the call of Albert Einstein in 1933, the IRC is-->
<!--          now at work in over 40 crisis-affected countries as well as communities throughout Europe and the Americas. In-->
<!--          the UK, the IRC provides expert integration and employment support to refugees, asylum seekers and vulnerable-->
<!--          migrants living in the UK from Syria, Afghanistan, Ukraine, Iraq, Sudan and beyond.<br>-->

<!--          <br>Learn more at: <span><a>www.rescue.org/uk</a></span> and follow the IRC on Twitter & Facebook.-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="chapter">-->
<!--        <p class="chapter-title">-->
<!--          About World Jewish Relief-->
<!--        </p>-->
<!--        <p class="chapter-text">-->
<!--          World Jewish Relief is the Jewish community's humanitarian agency. Founded in 1933 and proud to have rescued-->
<!--          over 65,000 Jews fleeing the Nazis, World Jewish Relief today supports vulnerable people from marginalised-->
<!--          communities, suffering the consequences of conflict and disaster to survive and thrive.<br>-->

<!--          <br>We work in 18 disaster affected countries and in towns and cities across the UK. From conflict stricken-->
<!--          eastern Ukraine to Afghan refugees in Bradford, and from climate migrants in Nepal to young women in Rwanda,-->
<!--          our impact is simultaneously global and local.<br>-->

<!--          <br>Learn more at: <span><a>www.worldjewishrelief.org</a></span>-->
<!--        </p>-->
<!--      </div>-->
<!--      <img alt="" src="@/assets/images/news/news-4.png">-->
<!--      <div class="chapter">-->
<!--        <p class="chapter-title">-->
<!--          About TERN:-->
<!--        </p>-->
<!--        <p class="chapter-text">-->
<!--          TERN is an ambitious social enterprise and ever-growing community on a mission to enable refugees to thrive-->
<!--          through the power of their own ideas. TERN helps refugees become entrepreneurs, launch businesses and take-->
<!--          control of their own futures, and has supported over 400 refugee founders since 2016. Alongside its-->
<!--          experienced volunteer community of over 300 industry experts, entrepreneurs and consultants, TERN's goal is to-->
<!--          launch 2,000 refugee-led businesses by 2025.-->
<!--        </p>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

import axios from "axios";
import sanitizeHTML from "sanitize-html"

export default {
  name: 'NewsReadMore',
  props:['url'],
  data(){
    return{
      news: {},
      urlFromLink: this.url
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+"/news/"+this.urlFromLink)
        .then((response)=>{
          this.news = response.data.news

        })
  },
  methods: {
    getFile(url){
      return process.env.VUE_APP_API_URL + url
    },
    cleanHtml(unsafeContent) {
      return sanitizeHTML(unsafeContent, {
        allowedTags: [
          "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
          "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
          "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
          "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
          "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
          "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
          "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "img"
        ],
        allowedAttributes: {
          a: [ 'href', 'name', 'target' ],
          // We don't currently allow img itself by default, but
          // these attributes would make sense if we did.
          img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ]
        },
      })
    }
  }
}

</script>


<style scoped lang="scss">

::v-deep{
  .body-news {
    img {
      width: 100%;
    }
    h5{
      font-size: 18px;
    }
  }
}

.container{
  padding: 0 1rem;
}
.container-all-news {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 4rem 0 7.5rem;

  .news-header {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .title {
      color: #024694;
      font-size: calc(var(--main-font-size) + 8px);
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      span {
        color: #373737;
        font-size: calc(var(--main-font-size) - 2px);
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .chapter {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .chapter-title {
      color: #184892;
      font-size: calc(var(--main-font-size) + 2px);
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }

    .chapter-text {
      color: #000;
      font-size: calc(var(--main-font-size) + 2px);
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      a{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>