<template>
  <div class="container-contact">
    <div class="banner">
      <div class="container">
        <div class="banner-content">
          <h1 class="text-default">
            <span>
            Latest news,
            </span><br>
            and events by REP
          </h1>
        </div>
      </div>
      <img src="@/assets/images/green-purple-corner.svg" alt="" class="corner">
    </div>
    <div class="container content">
      <div class="box-title">
        <p class="title">
          ANY QUESTIONS?
          <span>
          You can also contact us to get support with your application or ask any questions you might have.
        </span>
        </p>
        <div class="box-contacts">
          <div class="item-email">

            <div class="value-box">
              <div class="name">
                <img alt="" src="@/assets/icons/icon-email.svg">
                <p>Email:</p>
              </div>
              <div class="value">
                <p ref="email">rep.sw@rescue.org</p>
                <img alt="" src="@/assets/icons/content_copy.svg" @click="copyEmail">
                <Transition>
                  <div class="copy-notification" v-if="showCopyNotification">
                    Email was copied!
                  </div>
                </Transition>

              </div>
            </div>
            <img alt="" class="corner" src="@/assets/images/purple-corner.png">
          </div>
          <div class="item-phone">
            <div class="value-box">
              <p class="name">
                <img alt="" src="@/assets/icons/icon-call.svg">
                Free telephone:
              </p>
              <p class="value">
                0808 294 0124
              </p>
            </div>
            <img alt="" class="corner" src="@/assets/images/green-corner.png">
          </div>
        </div>
        <Accordion :contact="true" is-blue-arrow="true">
          <template v-slot:title>
            <h4>GIVE US YOUR FEEDBACK</h4>
          </template>
          <template v-slot:content>
            We are committed to providing high-quality services to all participants in the refugee employability
            programme. We value your feedback and take all complaints seriously. Your input helps us improve our
            services and address any concerns promptly.<br>

            <br>You have the right to present your compliments, concerns, suggestions and to file a complaints if you
            are
            unsatisfied with our services.<br>

            <br>You can provide feedback or file a complaint anonymously without revealing your identity if you wish.
            Your participation in the programme will not be interrupted or terminated as a result of your
            complaints.<br>

            <br>You will be treated fairly and given prompt unbiased consideration. You will also received follow up
            from us
            to inform you of the outcome of your complaint.<br>

            <br>How to make a complaint, offer feedback or suggestion:<br>
            Face-to- face: You can discuss directly with your case worker or their manager.
            Phone: You can call our free phone number 0808 294 0124 and choose option 4 to give feedback to the
            dedicated team member.<br>

            <br>Or submit a complaint to the address integrity@rescue.org
            You can also send a letter to our office addressing it to the REP Project manager on 100 Wood St, Barbican.
            London EC2V 7AN.<br><br>
          </template>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/Accordion.vue";

export default {
  name: 'ContactPage',
  components: {Accordion},
  data() {
    return {
      showCopyNotification: false
    }
  },
  methods: {
    copyEmail() {
      const storage = document.createElement('textarea');
      storage.value = this.$refs.email.innerHTML;
      this.$refs.email.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.email.removeChild(storage);
      this.showCopyNotification = true;
      setTimeout(() => {
        this.showCopyNotification = false;
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.content {
  min-height: 50vh;
}

.container-contact {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .banner {
    height: 482px;
    background-image: url("@/assets/images/banner-contact.png");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

    .corner {
      width:128px;
      position: absolute;
      top: 0;
      @media (max-width: 1224px) {
        width: 70px;
      }

    }

    @media (max-width: 1224px) {
      background: rgba(146, 197, 235, 1);
      height: 200px;

      .text-default {
        font-size: calc(var(--main-font-size) + 8px) !important;
      }
    }


    .text-default {
      font-size: calc(var(--main-font-size) + 32px);
      color: #034694;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 1.44px;

      span {
        font-weight: 400;
      }
    }
  }

  .box-title {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 7.5rem;

    .title {
      color: #024694;
      font-size: calc(var(--main-font-size) + 8px);
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 0.72px;
      display: flex;
      flex-direction: column;

      @media (max-width: 1224px) {
        font-size: calc(var(--main-font-size) + 4px);
      }

      span {
        margin-top: 16px;
        color: #262532;
        font-size: calc(var(--main-font-size) + 2px);
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .box-contacts {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .item-email {
      border-radius: 8px 0 0 8px;
      background: #A9DBD2;
      position: relative;
      padding: 32px;
      display: flex;
      gap: 1rem;

      .value-box {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .name {
          color: #000;
          font-size: calc(var(--main-font-size) + 8px);
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          height: fit-content;
          margin: auto 0;
          display: flex;
          gap: 0.5rem;

          p {
            height: fit-content;
            margin: auto;
          }
        }

        .value {
          @extend .name;
          color: #184892;
          position: relative;

          img {
            cursor: pointer;
          }

          .copy-notification {
            font-size: calc(var(--main-font-size) - 6px);
            position: absolute;
            right: -40px;
            top: 37px;
            background: $main-color;
            padding: 0 8px;
            height: 24px;
            display: flex;
            align-items: center;
            color: white;
            transition: opacity 0.5s ease;
          }
        }
      }
    }


    .corner {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;

    }
  }

  .item-phone {
    @extend .item-email;

    .corner {
      transform: rotate(270deg);
    }
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    padding: 0 1rem;
  }

}

@media only screen and (max-width: 1224px) {
  .container-contact {
    .contact-banner {
      background-image: none;
      background-color: #92c5eb;
      height: 200px;

      .text-default {
        font-size: calc(var(--main-font-size) + 8px);
      }
    }

    .box-title {
      .title {
        font-size: calc(var(--main-font-size) + 4px);

      }
    }

    .box-contacts {
      .item-email {
        border-radius: 8px;

        .value-box {
          gap: 0.5rem;
        }

        .corner {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container-contact {
    .box-contacts {
      .item-email {
        border-radius: 8px;

        .value-box {
          gap: 0.5rem;

          .name {
            font-size: calc(var(--main-font-size) + 4px);
          }
        }

      }
    }
  }
}


</style>